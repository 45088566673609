import * as ActiveStorage from "@rails/activestorage";
import "bootstrap-icons/font/bootstrap-icons.css";
import * as Turbo from "@hotwired/turbo";
import Rails from "@rails/ujs";
import "@hotwired/turbo-rails";
import "bootstrap";
import "channels";
import "animate.css";
import "chartkick/chart.js";
import "./application.scss";
import "stimulus";
import initializeDataTables from '../javascript/datatable';
import $ from "jquery";
window.$ = $;
window.jQuery = $;
import 'selectize';
import 'selectize/dist/css/selectize.default.css';
import "datatables.net-bs5";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";

Rails.start();
Turbo.start();
ActiveStorage.start();

document.addEventListener("DOMContentLoaded", function (event) {
  var popoverTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="popover"]')
  );
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl);
  });

  var tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  );
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });

  initializeDataTables()

});

$(document).on("turbo:load", function () {
  if (!$.fn.DataTable.isDataTable("table[id^=table-]")) {
    $("table[id^=table]").DataTable();
  }
  if (!$.fn.DataTable.isDataTable("table[id^=admin_table-]")) {
    $("table[id^=admin_table]").each(function () {
      var table = $(this);
      var tableIdsToSkipLastColumn = ['admin_table-exhibitor', 'admin_table-attendee', 'admin_table-exhibitors', 'admin_table-activation'];
      var headersToSkip = ['Actions', 'Action', 'Select', 'Activated'];
      var tableName = table.attr('id').split('-')[1];

      table.DataTable({
        dom: '<"top d-flex justify-content-between"<"length-menu"l><"search-and-buttons d-flex"fB>>rt<"bottom d-flex justify-content-between"ip><"clear">',
        buttons: [
          {
            extend: 'excel',
            text: 'Export to Excel',
            title: '',
            filename: tableName + '_' + new Date().toISOString().slice(0, 10),
            exportOptions: {
              columns: function (index, data, node) {
                if (tableIdsToSkipLastColumn.includes(table.attr('id'))) {
                  var headerText = table.find('th').eq(index).text().trim();
                  return !headersToSkip.includes(headerText);
                }
                return true;
              }
            }
          }
        ]
      });
    });
  }
});

$(document).on("turbo:before-cache", function () {
  var dataTable = $($.fn.dataTable.tables(true)).DataTable();
  if (dataTable !== null) {
    // dataTable.clear();
    dataTable.destroy();
    return (dataTable = null);
  }
});
