export default function initializeDataTables() {
	var allDataTableContainers = $('.list-container--datatables');

	if (allDataTableContainers.length) {
		allDataTableContainers.each(function(index, container) {
			var dataTable = $(container).find('.list-container__table');
			var dataTableContainerId = $(container).attr('id');

			if (dataTable.length && dataTableContainerId != '' && typeof dataTableContainerId != 'undefined') {
				var defaultOrderingIndex = dataTable.find('.table__th-sort-asc').parents('th').index() > -1 ? dataTable.find('.table__th-sort-asc').parents('th').index() : dataTable.find('.table__th-sort-desc').parents('th').index();
				var defaultOrderingDirection = dataTable.find('.table__th-sort-asc').parents('th').index() > -1 ? 'asc' : 'desc';
				if (defaultOrderingIndex == -1) {
					defaultOrderingIndex = 0;
					defaultOrderingDirection = 'asc';
				}

				var dataTableObject = dataTable.DataTable({
					dom: 'rt<"bottom"lip><"clear">',
					stateSave: true,
					order: [[defaultOrderingIndex, defaultOrderingDirection]],
					pagingType: 'simple_numbers',
					lengthMenu: [
						[10, 20, 50, 100, -1],
						[10, 20, 50, 100, 'All']
					],
					scrollX: true,
					scrollY: true,
					responsive: false,
					pageLength: 10,
					language: {
						oPaginate: {
							sNext: '<i class="bi bi-chevron-right"></i>',
							sPrevious: '<i class="bi bi-chevron-left"></i>',
							sFirst: '<i class="bi bi-chevron-right"></i>',
							sLast: '<i class="bi-solid bi-chevron-right"></i>',
						},
					},
					initComplete: (settings, json) => {
						dataTablesTogglePlaceholder(settings.oInstance.api(), dataTableContainerId);

						$(`#${dataTableContainerId} .dataTables_paginate`).appendTo(`#${dataTableContainerId} .table__pagination`);

						$(`#${dataTableContainerId} .dataTables_info`).appendTo(`#${dataTableContainerId} .table__showing-indicator`);
						dataTablesAdjustInfo($(`#${dataTableContainerId} .dataTables_info`));

						$(`#${dataTableContainerId} .dataTables_length`).appendTo(`#${dataTableContainerId} .table__show-per-page`);
						$(`#${dataTableContainerId} .table__show-per-page`).find('select').addClass('form-field__select');
						$(`#${dataTableContainerId} .dt-length`).prepend('<span class="show-text">Show</span> ');
						console.log($(`#${dataTableContainerId}`))
						dataTablesAdjustInfo($(`#${dataTableContainerId} .dt-info`))
						replaceTextOnly($(`#${dataTableContainerId} .dt-length label`), 'entries per page','Per Page');
					},
					drawCallback: function () {
						const paginate = $('#DataTables_Table_0_paginate');
						const ul = $('<ul class="pagination"></ul>');

						paginate.find('a.paginate_button').each(function () {
							const li = $('<li class="paginate_button page-item"></li>');
							if ($(this).hasClass('previous')) li.addClass('previous');
							if ($(this).hasClass('next')) li.addClass('next');
							if ($(this).hasClass('current')) li.addClass('active');
							$(this).wrap(li);
							ul.append($(this).parent());
						});

						paginate.find('ul').remove();
						paginate.append(ul);
					}
				});

				dataTableObject.on('draw.dt', function (e, settings, len) {
					dataTablesAdjustInfo($(`#${dataTableContainerId} .dataTables_info`));
					dataTablesAdjustInfo($(`#${dataTableContainerId} .dt-info`))

					dataTablesTogglePlaceholder(settings.oInstance.api(), dataTableContainerId);
				});

				var tableSearchInput = $(`#${dataTableContainerId} .list-container__table-search`);
				if (tableSearchInput.length) {
					tableSearchInput.on('keyup', function() {
						dataTableObject.search(this.value).draw();
					});
				}
			} else if (dataTable.length) {
				console.error('A DataTable is missing the id attribute.', dataTable)
			}
		});

		function dataTablesAdjustInfo(infoElement) {
			replaceTextOnly(infoElement, 'Showing ', '');
			replaceTextOnly(infoElement, ' to ', '-');
			replaceTextOnly(infoElement, ' entries', '');
		}

		function replaceTextOnly(element, textToReplace, textReplaceWith) {
			element.contents().filter(function() {
				return this.nodeType == 3
			}).each(function(){
				this.textContent = this.textContent.replace(textToReplace, textReplaceWith);
			});
		}

		function dataTablesTogglePlaceholder(dataTableObject, dataTableContainerId) {
			var noDataElement = $(`#${dataTableContainerId} .list-container__no-data`);
			var table = $(`#${dataTableContainerId} .list-container__table`);

			if (noDataElement == null) {
				console.error('This DataTable does not have a placeholder element implemented. Container: ', $(`#${dataTableContainerId}`));
				return;
			}

			if (table == null) {
				console.error('This DataTable does not have a table implemented. Container: ', $(`#${dataTableContainerId}`));
				return;
			}

			if (dataTableObject.page.info().pages == 0) {
				$(`#${dataTableContainerId}`).addClass('list-container--no-data');
			} else {
				$(`#${dataTableContainerId}`).removeClass('list-container--no-data');
			}
		}
	}
}